import React from 'react'
import Img from 'gatsby-image'

import ReactMarkdown from 'react-markdown'

const TeamMember = ({ member }) => {
  return (
    <article className='team-member'>
      <Img
        position='relative'
        className='team-member__image '
        fluid={member.image.childImageSharp.fluid}
      />
      <div className='team-member__content'>
        <h4 className='text-sm mb-0'>{member.position}</h4>
        <h2 className='text-h4 lg:text-h3'>{member.title}</h2>
        <ReactMarkdown
          className='team-member__description'
          source={member.description}></ReactMarkdown>
      </div>
    </article>
  )
}

export default TeamMember

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '@/components/Layout'
import TeamMember from '@/components/TeamMember'

import SEO from '@/helpers/SEOHelper.js'

class TeamPage extends React.Component {
  render() {
    const { data } = this.props
    const pageData = data.markdownRemark.frontmatter
    const { edges: teamMembers } = data.people
    const seo = pageData.seo

    return (
      <Layout>
        <Helmet>
          <meta name='description' content={SEO.description(seo.description)} />
          <meta property='og:title' content={SEO.title(seo.title)} />
          <meta
            property='og:description'
            content={SEO.description(seo.description)}
          />
          <meta property='og:image' content={SEO.image(seo.image)} />
          <title>{SEO.title(seo.title)}</title>
        </Helmet>
        <div className='top-bg-team top-bg  bg-grey-lightest' />
        <div className='container-lg pt-56 pb-24'>
          <h2 className='uppercase text-sm'>{pageData.hero.label}</h2>
          <h1 className='text-h3 sm:text-h1 mb-6'>{pageData.hero.heading}</h1>
        </div>

        <section className='container-lg'>
          <div className='team-feed'>
            {pageData.team.map((member, i) => {
              let person = teamMembers.find(({ node: person }) => {
                return member === person.frontmatter.title
              })
              if (person) {
                return (
                  <TeamMember
                    key={`${i}${person.node.id}`}
                    member={person.node.frontmatter}
                  />
                )
              } else {
                return ''
              }
            })}
          </div>
        </section>
      </Layout>
    )
  }
}

TeamPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default TeamPage

export const pageQuery = graphql`
  query TeamPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "team-page" } }) {
      frontmatter {
        hero {
          heading
          label
        }
        team
        seo {
          title
          description
        }
      }
    }
    people: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "team-member" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 450, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            position
            description
          }
        }
      }
    }
  }
`
